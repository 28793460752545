
import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            link: null,
        };
    },
    methods: {
        exportItems(): void {
            this.post('infringements/export', {
                ...this.params,
            })
                .then(({ data }) => {
                    this.link = data.filename;
                })
                .catch(() => {
                })
                .finally(() => {
                });
        },
    },
});
